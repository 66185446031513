<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        fill="none"
    >
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.83334 4C9.10949 4 9.33334 4.22386 9.33334 4.5L9.33334 12.5C9.33334 12.7761 9.10949 13 8.83334 13C8.5572 13 8.33334 12.7761 8.33334 12.5L8.33334 4.5C8.33334 4.22386 8.5572 4 8.83334 4Z"
            fill="currentColor"
        />
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6667 8.5C13.6667 8.77614 13.4428 9 13.1667 9L4.49999 9C4.22385 9 3.99999 8.77614 3.99999 8.5C3.99999 8.22386 4.22385 8 4.49999 8L13.1667 8C13.4428 8 13.6667 8.22386 13.6667 8.5Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
